

































































































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, AdsModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Просмотр статистики'
  }
})
export default class PriceAds extends Vue {
  public tableSummary: Array<object>
  public selectedObjects: Array<object>
  public ads: Array<object>
  public pageID: string
  public date: string
  public datePickerMenu: boolean
  public expensesDates: Array<string>
  public expensesDialog: boolean
  public expensesData: object

  constructor() {
    super()
    this.pageID = '0'
    this.date = ''
    this.ads = []
    this.selectedObjects = []
    this.datePickerMenu = false
    this.expensesDates = []
    this.expensesDialog = false
    this.expensesData = {}
    this.tableSummary = [
      {
        text: 'Площадка',
        value: 'name',
        sortable: false
      },
      {
        text: 'Объявление',
        value: 'url',
        sortable: false
      },
      {
        text: 'Просмотры',
        value: 'cardShows',
        sortable: false
      },
      {
        text: 'Просмотры телефона',
        value: 'phoneShows',
        sortable: false
      },
      {
        text: 'Звонки',
        value: 'calls',
        sortable: false
      },
      {
        text: 'Затраты',
        value: 'expenses',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @AdsModule.Action('getOne') getOne!: (id: number | string) => Promise<any>
  @AdsModule.Action('getOneUpdate') getOneUpdate!: (id: number | string) => Promise<any>
  @AdsModule.Action('getExpenses') getExpensesStore!: (payload: Payload) => Promise<any>

  // Watch
  @Watch('expensesDates') checkDates() {
    if (this.expensesDates.length === 2 && this.expensesDates[0] >= this.expensesDates[1]) {
      this.expensesDates = [this.expensesDates[1], this.expensesDates[0]]
    }

    if (this.expensesDates.length === 2 && this.expensesDates[0] === this.expensesDates[1]) {
      this.expensesDates = [this.expensesDates[0]]
    }
  }

  get expensesDatesText() {
    return this.expensesDates ? this.expensesDates.join(' ~ ') : ''
  }

  created() {
    this.pageID = this.$route.params.id
    this.getOne(this.pageID)
      .then((res) => {
        this.ads = res.data
        this.date = res.date
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  getExpenses() {
    if (!this.expensesDates[0]) {
      return this.$noty('error', 'Укажите хотя бы одну дату!', 7000).show()
    }

    this.getExpensesStore({
      id: this.pageID,
      dateStart: this.expensesDates[0],
      dateEnd: this.expensesDates[1]
    })
      .then((res) => {
        this.expensesData = res
        this.expensesDialog = true
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  update() {
    this.getOneUpdate(this.pageID)
      .then((res) => {
        this.ads = res.data
        this.date = res.date
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  disable() {
    this.$noty('error', 'Отключение невозможно', 7000).show()
  }
}
